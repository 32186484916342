import { AnimatePresence, motion } from "framer-motion";
import { ApiClient } from "../../client/ApiClient";
import { CardId, PosId, Rank } from "../../client/basic-types";
import { DmCombination, DmPlayStatePhase } from "../../client/server-types-python";
import Switch from "../Switch";
import styled from "styled-components";
import { useTableContext } from "../TableContext";
import { CardDetails } from "../../model/CardDetails";
import { Card } from "./Card";
import { MoveUtil } from "../../model/MoveUtil";
import { Dispatch, SetStateAction } from "react";
import { Timer } from "../Timer";

const ControlsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const LeftControls = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
`;

const MainControl = styled.div`
    display: flex;
    justify-content: center;
    flex: 0;
`;

const RightControls = styled.div`
    display: flex;
    justify-content: flex-start;
    flex: 1;
`;

interface PlayControlsBarProps {
    apiClient: ApiClient;
    waitingOnSubmittedMove: boolean;
    selectedCards: CardId[];
    suggestedPlays: DmCombination[];
    wishValue: number | "No wish" | undefined;
    setWishValue: Dispatch<SetStateAction<Rank | "No wish" | undefined>>;
    betChoice: boolean;
    setBetChoice: (value: boolean) => void;
    playSelectedCards: () => void;
    playSuggestedCards: (suggestedPlay: DmCombination) => void;
    chooseGrandBet: (choice: boolean) => void;
    submitTrade: () => void;
    submitCancelTrade: () => void;
    tradeSlateCards: (CardId | null)[];
    tradeSlateDirty: boolean;
    autoplayTimeoutEnd: number | null;
}

export const PlayControlsBar = ({
    apiClient,
    waitingOnSubmittedMove,
    selectedCards,
    suggestedPlays,
    wishValue,
    setWishValue,
    betChoice,
    setBetChoice,
    playSelectedCards,
    playSuggestedCards,
    chooseGrandBet,
    submitTrade,
    submitCancelTrade,
    tradeSlateCards,
    tradeSlateDirty,
    autoplayTimeoutEnd,
}: PlayControlsBarProps): JSX.Element => {
    /*
     * Preconditions:
     * - playerSeat is defined
     * - gameplay context exists
     * - not in replay mode
     */
    const tc = useTableContext()!;
    const gc = tc.gameplayContext!;
    const playerSeat = tc.playerSeat as PosId;

    const table = tc.table;
    const playState = gc.displayedPlayState;
    const thisPlayerIsActive = playerSeat === playState.active_player;

    const isGrandPhase = playState.phase === DmPlayStatePhase.GRAND_PHASE;
    // const isTradePhase = playState.phase === DmPlayStatePhase.TRADE_PHASE;
    // const isPlayPhaseTurn = playState.phase === DmPlayStatePhase.PLAY_PHASE && !playState.is_engine_turn;
    const isEngineTurn = playState.is_engine_turn;

    const isSpecialDecision = playState.give_dragon || playState.make_wish;
    const isLead = playState.winning_player === null;

    const thisPlayerHand = playState.positions[playerSeat].hand_cards;
    const betAvailable = thisPlayerHand.length === 14;

    return (
        <ControlsContainer>
            <LeftControls>
                <AnimatePresence>
                    {thisPlayerIsActive && playState.make_wish && (
                        <div className="wish-select" key="wish-select">
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-none"
                                value="None"
                                onChange={(e) => setWishValue("No wish")}
                            ></input>
                            <label htmlFor="wish-selection-none">None</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-2"
                                value="2"
                                onChange={(e) => setWishValue(2)}
                            ></input>
                            <label htmlFor="wish-selection-2">2</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-3"
                                value="3"
                                onChange={(e) => setWishValue(3)}
                            ></input>
                            <label htmlFor="wish-selection-3">3</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-4"
                                value="4"
                                onChange={(e) => setWishValue(4)}
                            ></input>
                            <label htmlFor="wish-selection-4">4</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-5"
                                value="5"
                                onChange={(e) => setWishValue(5)}
                            ></input>
                            <label htmlFor="wish-selection-5">5</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-6"
                                value="6"
                                onChange={(e) => setWishValue(6)}
                            ></input>
                            <label htmlFor="wish-selection-6">6</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-7"
                                value="7"
                                onChange={(e) => setWishValue(7)}
                            ></input>
                            <label htmlFor="wish-selection-7">7</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-8"
                                value="8"
                                onChange={(e) => setWishValue(8)}
                            ></input>
                            <label htmlFor="wish-selection-8">8</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-9"
                                value="9"
                                onChange={(e) => setWishValue(9)}
                            ></input>
                            <label htmlFor="wish-selection-9">9</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-10"
                                value="10"
                                onChange={(e) => setWishValue(10)}
                            ></input>
                            <label htmlFor="wish-selection-10">10</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-jack"
                                value="11"
                                onChange={(e) => setWishValue(11)}
                            ></input>
                            <label htmlFor="wish-selection-jack">J</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-queen"
                                value="12"
                                onChange={(e) => setWishValue(12)}
                            ></input>
                            <label htmlFor="wish-selection-queen">Q</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-king"
                                value="13"
                                onChange={(e) => setWishValue(13)}
                            ></input>
                            <label htmlFor="wish-selection-king">K</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-ace"
                                value="14"
                                onChange={(e) => setWishValue(14)}
                            ></input>
                            <label htmlFor="wish-selection-ace">A</label>
                        </div>
                    )}

                    {thisPlayerIsActive && !playState.is_trade_phase && !isGrandPhase && betAvailable && (
                        <Switch leftOption="No call" rightOption="Tichu!" value={betChoice} onChange={setBetChoice} />
                    )}

                    {isGrandPhase && playState.grand_phase_state!.grand_bet_choices[playerSeat] === null && (
                        <Switch leftOption="No call" rightOption="Grand!" value={betChoice} onChange={setBetChoice} />
                    )}
                </AnimatePresence>
            </LeftControls>
            <MainControl>
                {isGrandPhase && playState.grand_phase_state!.grand_bet_choices[playerSeat] === null && (
                    <motion.button
                        layout={"position"}
                        transition={{ duration: 0.1 }}
                        onClick={() => chooseGrandBet(betChoice)}
                        className="playCardsButton easyButton"
                        key="mainControl"
                        disabled={waitingOnSubmittedMove}
                    >
                        Go
                    </motion.button>
                )}
                {!(thisPlayerIsActive && playState.give_dragon) && !playState.is_trade_phase && !isGrandPhase && (
                    <motion.button
                        layout={"position"}
                        transition={{ duration: 0.1 }}
                        onClick={playSelectedCards}
                        className="playCardsButton easyButton"
                        key="mainControl"
                        disabled={
                            waitingOnSubmittedMove ||
                            !thisPlayerIsActive ||
                            ((isSpecialDecision || isEngineTurn) && selectedCards.length > 0) ||
                            playState.give_dragon ||
                            (isLead && selectedCards.length === 0) ||
                            (playState.make_wish && wishValue === undefined)
                        }
                    >
                        {playState.make_wish ? "Wish" : selectedCards.length ? "Play" : "Pass"}
                    </motion.button>
                )}

                {playState.is_trade_phase && playState.positions[playerSeat].trade_slate !== null && (
                    <button onClick={submitCancelTrade} className="cancelTradeButton easyButton" key={"tradeCancel"}>
                        {"Cancel Trade"}
                    </button>
                )}

                {playState.is_trade_phase && (
                    <button
                        onClick={submitTrade}
                        className="playCardsButton easyButton"
                        disabled={!tradeSlateDirty || tradeSlateCards.includes(null)}
                        key={"mainControl"}
                    >
                        {playState.positions[playerSeat].trade_slate !== null ? "Update Trade" : "Trade"}
                    </button>
                )}

                {thisPlayerIsActive && playState.give_dragon && (
                    <div style={{ display: "flex" }} key="mainControl">
                        <button
                            onClick={() => apiClient.makeMove(table.id, MoveUtil.getGiveDragonMove(playerSeat, true))}
                            className="playCardsButton easyButton"
                            disabled={!thisPlayerIsActive}
                        >
                            ←
                        </button>
                        <div className="giveDragonCenter">🐲</div>
                        <button
                            onClick={() => apiClient.makeMove(table.id, MoveUtil.getGiveDragonMove(playerSeat, false))}
                            className="playCardsButton easyButton"
                            disabled={!thisPlayerIsActive}
                        >
                            →
                        </button>
                    </div>
                )}
            </MainControl>
            <RightControls>
                {suggestedPlays.length > 0 && (
                    <>
                        {suggestedPlays.map((suggestedPlay, index) => (
                            <motion.button
                                layout
                                transition={{ duration: 0.1 }}
                                onClick={() => playSuggestedCards(suggestedPlay)}
                                className="suggestedPlayButton easyButton"
                                disabled={
                                    !thisPlayerIsActive ||
                                    ((isSpecialDecision || isEngineTurn) && selectedCards.length > 0) ||
                                    playState.give_dragon
                                }
                                key={`play_${index}`}
                            >
                                {suggestedPlay.cards.map((cardId) => (
                                    <Card
                                        key={cardId}
                                        cardDetails={CardDetails.getCardDetails(cardId)}
                                        onClick={() => {}}
                                        selected={false}
                                        textOnly={true}
                                    />
                                ))}
                            </motion.button>
                        ))}
                    </>
                )}
                {autoplayTimeoutEnd && <Timer endTime={autoplayTimeoutEnd} />}
            </RightControls>
        </ControlsContainer>
    );
};
