import styled from "styled-components";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useState } from "react";

const TimerContainer = styled(motion.div)`
    display: flex;
    align-items: center;
`;

const CircleContainer = styled.div`
    width: 50px;
    height: 50px;
    position: relative;
`;

const CircleBackground = styled.circle`
    fill: none;
    stroke: ${(props) => props.theme.colors.primary_hinted_white};
`;

const CircleProgress = styled(motion.circle)`
    fill: none;
    stroke: ${(props) => props.theme.colors.primary_very_light};
    transform-origin: center;
    transform: rotate(-90deg);
`;

const Text = styled.span`
    font-size: 16px;
    color: #333;
`;

interface TimerProps {
    endTime: number;
}

export const Timer = ({ endTime }: TimerProps) => {
    const DURATION = 1000; // 1 second
    const [timeLeft, setTimeLeft] = useState(Math.max(0, endTime - Date.now()));

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeLeft = Math.max(0, endTime - Date.now());
            setTimeLeft(newTimeLeft);
            if (newTimeLeft === 0) {
                clearInterval(interval);
            }
        }, 16);

        return () => clearInterval(interval);
    }, [endTime]);

    const progress = (DURATION - timeLeft) / DURATION;
    const radius = 7;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference * (1 - progress);

    return (
        <TimerContainer initial={{ opacity: 0 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0 }}>
            <CircleContainer>
                <svg width="50" height="50" viewBox="0 0 50 50">
                    <CircleBackground cx="25" cy="25" r={radius} strokeWidth="14" />
                    <CircleProgress
                        cx="25"
                        cy="25"
                        r={radius}
                        strokeWidth="14"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                        strokeLinecap="butt"
                    />
                </svg>
            </CircleContainer>
            <Text>Autopassing...</Text>
        </TimerContainer>
    );
};
