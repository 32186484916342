import { ActionId, CardId } from "../client/basic-types";
import { CombinationType, DmCombination } from "../client/server-types-python";
import { ACTIONS } from "./Actions";
import { CardDetails, Suit } from "./CardDetails";

/**
 * Provides convenience to players by suggesting legal moves using their selected cards, to
 * be displayed as one-click options.
 */
export class ActionMoveAssistant {
    public static suggestMoves(
        legalActions: ActionId[],
        selectedCardIds: CardId[],
        handCardIds: CardId[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        const unselectedHandCardIds = handCardIds.filter((cardId) => !selectedCardIds.includes(cardId));

        const selectedCards = selectedCardIds.map((cardId) => CardDetails.getCardDetails(cardId));
        const unselectedHandCards = unselectedHandCardIds.map((cardId) => CardDetails.getCardDetails(cardId));
        const relevantWish = wish && selectedCards.some((card) => card.rank === wish) ? wish : undefined;
        // This list doesn't include the straight flush bomb, because - since these are all compressed into one action -
        // we need to process them separately.
        const relevantLegalActions = legalActions.filter((actionId) => actionId >= 1 && actionId <= 324);
        const canStraightFlushBomb = legalActions.some((actionId) => actionId === 325);

        // TODO: make action choice more sophisticated
        if (relevantLegalActions.length === 0) {
            return [];
        }
        const result: DmCombination[] = [];
        let minActionIndex = 0;
        for (; minActionIndex < relevantLegalActions.length; minActionIndex++) {
            const move = this.actionToMove(
                relevantLegalActions[minActionIndex],
                selectedCards,
                unselectedHandCards,
                cardIdsKnownByOpps,
                cardIdsKnownByPartner,
                winningCombination,
                relevantWish
            );
            if (move) {
                result.push(move);
                break;
            }
        }
        if (canStraightFlushBomb) {
            const straightFlushBombs = this._getStraightFlushBombSuggestions(
                selectedCards,
                handCardIds.map((cardId) => CardDetails.getCardDetails(cardId)),
                winningCombination,
                relevantWish
            );
            if (straightFlushBombs.length > 0) {
                if (result.length > 0) {
                    // We already found a weak move, so only add the highest-ranked straight flush bomb.
                    result.push(straightFlushBombs[straightFlushBombs.length - 1]);
                } else {
                    // No existing move, so we can have a weak and a strong straight flush bomb, if there are two.
                    result.push(...straightFlushBombs);
                }
                return result;
            }
        }
        let maxActionIndex = relevantLegalActions.length - 1;
        for (; maxActionIndex > minActionIndex; maxActionIndex--) {
            const move = this.actionToMove(
                relevantLegalActions[maxActionIndex],
                selectedCards,
                unselectedHandCards,
                cardIdsKnownByOpps,
                cardIdsKnownByPartner,
                winningCombination,
                relevantWish
            );
            if (move) {
                result.push(move);
                break;
            }
        }
        return result;
    }

    /**
     * Picks a number of cards from a list of candidates, preferring cards that are known by opponents and not known by partner.
     * @param candidates a list of cards to pick from
     * @param needed a number of cards to pick, lower than the length of candidates
     * @param cardIdsKnownByOpps a list of cards known by opponents
     * @param cardIdsKnownByPartner a list of cards known by partner
     */
    private static pickCards(
        candidates: CardDetails[],
        needed: number,
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[]
    ): CardId[] {
        const bestCards: CardId[] = [];
        const backupCards: CardId[] = [];
        for (const card of candidates) {
            if (cardIdsKnownByOpps.includes(card.cardId)) {
                bestCards.push(card.cardId);
            } else if (!cardIdsKnownByPartner.includes(card.cardId)) {
                backupCards.push(card.cardId);
            }
        }
        if (bestCards.length >= needed) {
            return bestCards.slice(0, needed);
        }
        if (bestCards.length === needed) {
            return bestCards;
        }
        return bestCards.concat(backupCards.slice(0, needed - bestCards.length));
    }

    /**
     * Converts an action - which must be legal given the hand cards, winning combination and wish - into a move.
     * It must use at least one more card that wasn't selected.
     *
     * Winning combination is only relevant for single phoenix and for overbombing bombs.
     * relevantWish should only be provided if the hand can fulfill the wish.
     *
     * @returns null if the action is not a valid move given the selected cards.
     */
    public static actionToMove(
        actionId: ActionId,
        selectedCards: CardDetails[],
        unselectedHandCards: CardDetails[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        relevantWish?: number
    ): DmCombination | null {
        const action = ACTIONS[actionId];
        const combination_type = action.combinationType;
        const rank = action.rank;
        if (
            combination_type === CombinationType.PASS ||
            combination_type === CombinationType.BET ||
            combination_type === CombinationType.GRAND_BET ||
            combination_type === CombinationType.MAKE_WISH ||
            combination_type === CombinationType.GIVE_DRAGON
        ) {
            return {
                combination_type,
                cards: [],
                rank,
            };
        }
        if (action.isBomb) {
            if (action.combinationType === CombinationType.STRAIGHT_FLUSH_BOMB) {
                // TODO: implement as separate function
                return null;
            }
            // Quad bomb is fairly simple.
            const selectedCardsOfRank = selectedCards.filter((card) => card.rank === action.rank);
            if (selectedCardsOfRank.length === 4 || selectedCardsOfRank.length !== selectedCards.length) {
                // Too many selected cards.
                return null;
            }
            const cardsOfRank = selectedCardsOfRank.concat(unselectedHandCards.filter((card) => card.rank === action.rank));
            cardsOfRank.sort((a, b) => a.sortOrder - b.sortOrder);
            return {
                combination_type,
                cards: cardsOfRank.map((card) => card.cardId),
                rank: rank,
            };
        }
        if (action.id >= 1 && action.id <= 17) {
            if (selectedCards.length > 0) {
                return null;
            }
            if (action.id <= 4) {
                // Special cards
                return {
                    combination_type,
                    cards: [action.id - 1], // First four cards are Ph Dr Dog 1. Pass aside, those are the first actions.
                    rank: action.id === 1 && winningCombination ? winningCombination.rank : action.rank,
                };
            }
            // Normal cards
            const cardsOfRank = unselectedHandCards.filter((card) => card.rank === rank);
            const cards =
                cardsOfRank.length === 1
                    ? [cardsOfRank[0].cardId]
                    : this.pickCards(cardsOfRank, 1, cardIdsKnownByOpps, cardIdsKnownByPartner);
            return {
                combination_type,
                cards,
                rank,
            };
        }
        if (selectedCards.some((card) => card.cardId === CardId.DOG || card.cardId === CardId.DRAGON)) {
            // Dragon and dog can only be played alone
            return null;
        }
        const neededRankCounts = action.rankCounts.slice();
        const neededSize = neededRankCounts.reduce((sum, count) => sum + count, 0);
        if (neededSize <= selectedCards.length) {
            // Move must require at least one more card than selected.
            return null;
        }

        // Whether the user has selected the phoenix
        let phoenixSelected = false;

        // Apply all selected cards (except phoenix) to ranks
        for (const card of selectedCards) {
            if (card.cardId === CardId.PHOENIX) {
                phoenixSelected = true;
            } else if (card.cardId === CardId.DOG || card.cardId === CardId.DRAGON) {
                // Dragon and dog can only be played alone
                return null;
            } else {
                if (--neededRankCounts[card.rank] < 0) {
                    return null;
                }
            }
        }
        // Whether there's a phoenix anywhere in the hand that hasn't been assigned to a rank
        let phoenixUnused = phoenixSelected || unselectedHandCards.some((card) => card.cardId === CardId.PHOENIX);
        let phoenixRank = -1;

        // Find cards to fill ranks that still need cards
        const additionalCardIds: CardId[] = [];
        let minRank = 1;
        let maxRank = 14;
        // Find the highest rank that still needs cards, so that we can use the phoenix there if needed
        while (neededRankCounts[maxRank] === 0) {
            maxRank--;
        }
        let lastChancePhoenixRank = maxRank;
        if (maxRank === relevantWish && combination_type === CombinationType.STRAIGHT && phoenixSelected) {
            lastChancePhoenixRank--;
            while (neededRankCounts[lastChancePhoenixRank] === 0) {
                lastChancePhoenixRank--;
            }
        }
        for (let rank = minRank; rank <= maxRank; rank++) {
            const n = neededRankCounts[rank];
            if (n > 0) {
                const options = unselectedHandCards.filter((card) => card.rank === rank);

                if (options.length === n) {
                    if (rank === lastChancePhoenixRank && phoenixUnused && phoenixSelected) {
                        if (n > 1) {
                            additionalCardIds.push(...this.pickCards(options, n - 1, cardIdsKnownByOpps, cardIdsKnownByPartner));
                        }
                        additionalCardIds.push(CardId.PHOENIX);
                        phoenixUnused = false;
                        phoenixRank = rank;
                    } else {
                        additionalCardIds.push(...options.map((card) => card.cardId));
                    }
                } else if (options.length + 1 === n && phoenixUnused) {
                    additionalCardIds.push(...options.map((card) => card.cardId));
                    additionalCardIds.push(CardId.PHOENIX);
                    phoenixUnused = false;
                    phoenixRank = rank;
                } else if (options.length > n) {
                    if (rank === lastChancePhoenixRank && phoenixUnused && phoenixSelected) {
                        if (n > 1) {
                            additionalCardIds.push(...this.pickCards(options, n - 1, cardIdsKnownByOpps, cardIdsKnownByPartner));
                        }
                        additionalCardIds.push(CardId.PHOENIX);
                        phoenixUnused = false;
                        phoenixRank = rank;
                    } else {
                        additionalCardIds.push(...this.pickCards(options, n, cardIdsKnownByOpps, cardIdsKnownByPartner));
                    }
                } else {
                    throw new Error("Invalid state");
                }
            }
        }

        const mergedCardIds = [
            ...selectedCards.filter((card) => card.cardId !== CardId.PHOENIX).map((card) => card.cardId),
            ...additionalCardIds,
        ];

        if (combination_type === CombinationType.STRAIGHT) {
            // If we inadvertently made a straight flush bomb, just return null. This clearly isn't a good suggestion.
            const suit = CardDetails.getCardDetails(mergedCardIds[0]).suit;
            if (mergedCardIds.every((cardId) => CardDetails.getCardDetails(cardId).suit === suit)) {
                return null;
            }
        }

        const phoenixSortOrder = phoenixRank * 10 + 5;
        const sortedCardIds = mergedCardIds.sort(
            (a, b) =>
                (a === CardId.PHOENIX ? phoenixSortOrder : CardDetails.getCardDetails(a).sortOrder) -
                (b === CardId.PHOENIX ? phoenixSortOrder : CardDetails.getCardDetails(b).sortOrder)
        );
        return {
            combination_type,
            cards: sortedCardIds,
            rank,
        };
    }

    /**
     * Based on the similar method in MoveAssistant, but this ignores quad bombs.
     * It currently returns nothing if no cards are selected.
     * Returns up to two possible straight flush bombs that can be made with the selected cards.
     * @param selectedCards the cards that have been selected
     * @param handCards the cards that are in the player's hand
     * @param winningCombination the current winning combination
     * @param wish the current wish
     * @returns up to two possible straight flush bombs
     */
    public static _getStraightFlushBombSuggestions(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length === 0) {
            // Don't suggest with nothing selected. TODO: Maybe we can still suggest?
            return [];
        }
        if (selectedCards[0].suit === Suit.UNKNOWN) {
            // Can't bomb with special cards.
            return [];
        }
        const result: DmCombination[] = [];
        const possibleSuit = selectedCards[0].suit;
        let suitBombPossible = true;
        for (const card of selectedCards) {
            if (card.suit !== possibleSuit) {
                suitBombPossible = false;
            }
        }
        if (suitBombPossible) {
            // find adjoining cards in suit
            const suitedCards = handCards.filter((card) => card.suit === possibleSuit);
            if (suitedCards.length >= 5) {
                const sortedCards = suitedCards.sort((a, b) => a.rank - b.rank);
                let leftIndex = 0;
                let rightIndex = 1;
                let requiredRanks: number[] | null = null; // lazy init - find any bomb first!
                while (rightIndex < sortedCards.length) {
                    while (
                        rightIndex + 1 < sortedCards.length &&
                        sortedCards[rightIndex + 1].rank - sortedCards[leftIndex].rank === rightIndex + 1 - leftIndex
                    ) {
                        rightIndex++;
                    }
                    const isRun = sortedCards[rightIndex].rank - sortedCards[leftIndex].rank === rightIndex - leftIndex;
                    if (isRun) {
                        if (rightIndex - leftIndex >= 4 && rightIndex - leftIndex + 1 > selectedCards.length) {
                            // This is a bomb. Check if it uses all selected cards.
                            if (requiredRanks === null) {
                                requiredRanks = selectedCards.map((card) => card.rank);
                                if (wish !== undefined && !requiredRanks.includes(wish)) {
                                    requiredRanks.push(wish);
                                }
                                requiredRanks.sort((a, b) => a - b);
                            }
                            if (
                                requiredRanks[0] >= sortedCards[leftIndex].rank &&
                                requiredRanks[requiredRanks.length - 1] <= sortedCards[rightIndex].rank
                            ) {
                                const length = rightIndex - leftIndex + 1;
                                const rank = sortedCards[rightIndex].rank;
                                const winningCombinationIsSfBomb =
                                    winningCombination !== undefined &&
                                    winningCombination.combination_type === CombinationType.STRAIGHT_FLUSH_BOMB;
                                const winningBombLength = winningCombinationIsSfBomb ? winningCombination.cards.length : 0;
                                const winningBombRank = winningCombinationIsSfBomb ? winningCombination.rank : 0;
                                if (
                                    !winningCombinationIsSfBomb ||
                                    (winningBombLength === length && winningBombRank < rank) ||
                                    winningBombLength < length
                                ) {
                                    result.push({
                                        combination_type: CombinationType.STRAIGHT_FLUSH_BOMB,
                                        cards: sortedCards.slice(leftIndex, rightIndex + 1).map((card) => card.cardId),
                                        rank: rank,
                                    });
                                    // We push up to two bombs:
                                    // 1) The longest one
                                    // 2) the weakest one - either a 4-bomb or the lowest possible 5-bomb that uses all the cards
                                    if (length > 5 && result.length === 1) {
                                        let minLength = 5;
                                        let minRank = 0;
                                        if (winningCombinationIsSfBomb) {
                                            minLength = winningBombLength;
                                            minRank = winningBombRank + 1;
                                            if (minRank > rank) {
                                                minLength += 1;
                                                minRank = 0;
                                            } else {
                                                const minLowestRank = minRank - minLength + 1;
                                                if (requiredRanks.some((r) => r < minLowestRank)) {
                                                    minLength += 1;
                                                    minRank = 0;
                                                }
                                            }
                                        }
                                        if (length > minLength) {
                                            while (
                                                rightIndex - leftIndex + 1 > minLength &&
                                                !requiredRanks.includes(sortedCards[rightIndex].rank) &&
                                                sortedCards[rightIndex].rank > minRank
                                            ) {
                                                rightIndex--;
                                            }
                                            while (
                                                rightIndex - leftIndex + 1 > minLength &&
                                                !requiredRanks.includes(sortedCards[leftIndex].rank)
                                            ) {
                                                leftIndex++;
                                            }
                                            result.unshift({
                                                combination_type: CombinationType.STRAIGHT_FLUSH_BOMB,
                                                cards: sortedCards.slice(leftIndex, rightIndex + 1).map((card) => card.cardId),
                                                rank: sortedCards[rightIndex].rank,
                                            });
                                        }
                                    }
                                }
                                // We found the connected group that has the required cards. There won't be more.
                                return result;
                            }
                        }
                    }

                    leftIndex = rightIndex;
                    rightIndex++;
                }
            }
        }
        return result;
    }
}
