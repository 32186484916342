import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DmTable, DmTableParticipant, DmTableSettings, GameMode } from "../client/server-types-python";
import theme from "../theme";
import { UserContext } from "../UserContext";
import { getAvatarUrl, getBotAvatarUrl } from "../misc/Avatars";

const SetupContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
`;

const SeatsContainer = styled.div`
    display: grid;
    grid-template-areas:
        ". seat3 ."
        "seat2 . seat4"
        ". seat1 .";
    gap: 20px;
    width: 400px;
    height: 400px;
`;

const Seat = styled.div<{ $gridArea: string; $isCurrentUser: boolean }>`
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    grid-area: ${(props) => props.$gridArea};
    background-color: ${(props) => (props.$isCurrentUser ? theme.colors.primary_dark : theme.colors.primary_light)};
    color: ${theme.colors.white_text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
`;

const AvatarImage = styled.img<{ $scaleFactorOnHover: number }>`
    width: 64px;
    height: 64px;
    opacity: 0.8;

    ${Seat}:hover & {
        width: ${(props) => props.$scaleFactorOnHover * 64}px;
        height: ${(props) => props.$scaleFactorOnHover * 64}px;
    }
`;

const ActionButton = styled.button<{ $disabled?: boolean }>`
    width: 120px;
    height: 50px;
    margin: 8px;
    border-radius: 8px;
    color: ${(props) => (props.$disabled ? theme.colors.black_text_disabled : theme.colors.black_text)};
    background-color: ${(props) => (props.$disabled ? theme.colors.action_disabled : theme.colors.action)};
    border: 1px solid darkgray;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};

    &:hover {
        background-color: ${(props) => (props.$disabled ? theme.colors.action_disabled : theme.colors.action_hover)};
    }
`;

const PrimaryButton = styled.button`
    padding: 5px 10px;
    margin: 2px;
    border-radius: 5px;
    color: ${theme.colors.white_text};
    background-color: ${theme.colors.primary};
    border: none;
    font-size: 0.9em;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${theme.colors.primary_dark};
    }
`;

const HoverOnlyButton = styled(PrimaryButton)`
    display: none;
    ${Seat}:hover & {
        display: block;
    }
`;

const LeaveButton = styled(HoverOnlyButton)`
    background-color: ${theme.colors.primary_light};
    &:hover {
        background-color: ${theme.colors.primary_medium};
    }
`;

// Add these new styled component definitions
const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 400px;
`;

const SettingsContainer = styled.div`
    background-color: ${theme.colors.primary_very_light};
    border-radius: 5px;
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const SettingsGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const StyledSelect = styled.select`
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.primary_dull};
    background-color: ${theme.colors.primary_hinted_white};
    color: ${theme.colors.black_text};
    width: 100%;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
`;

const SettingsLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.colors.black_text};
`;

const SettingsItem = styled.div`
    margin-bottom: 15px;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

interface TableSetupProps {
    table: DmTable;
    addBot: (seat: number) => void;
    startTable: () => void;
    updateSettings: (settings: Partial<DmTableSettings>) => void;
    changeSeat: (seat: number) => void;
    removeBot: (seat: number) => void;
    leaveTable: () => void;
}

export const TableSetup: React.FC<TableSetupProps> = ({ table, addBot, startTable, updateSettings, changeSeat, removeBot, leaveTable }) => {
    const [gameMode, setGameMode] = useState(table.settings.game_mode || GameMode.STANDARD);
    const [shufflePlayers, setShufflePlayers] = useState(table.settings.shuffle_players === 1);

    useEffect(() => {
        if (table.settings.game_mode !== GameMode.NONE) {
            setGameMode(table.settings.game_mode);
        }
    }, [table.settings.game_mode]);

    useEffect(() => {
        setShufflePlayers(table.settings.shuffle_players === 1);
    }, [table.settings.shuffle_players]);

    const userContext = React.useContext(UserContext);
    if (!userContext) {
        return <></>;
    }
    const { user } = userContext;

    const handleGameModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newGameMode = e.target.value as unknown as GameMode;
        if (newGameMode === GameMode.NONE) {
            return;
        } else {
            setGameMode(newGameMode);
            updateSettings({ game_mode: newGameMode });
        }
    };

    const handleShufflePlayersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShufflePlayers(e.target.checked);
        updateSettings({ shuffle_players: e.target.checked ? 1 : 0 });
    };

    const renderSeat = (participant: DmTableParticipant | null, index: number) => {
        const isOwnSeat = participant?.user?.id === user?.id;
        return (
            <Seat key={index} $gridArea={`seat${index + 1}`} $isCurrentUser={participant?.user?.id === user?.id}>
                {participant ? (
                    participant.user ? (
                        <>
                            <AvatarImage $scaleFactorOnHover={0.8} src={getAvatarUrl("", participant.user.avatar)} alt="Player avatar" />
                            <div>{participant.user.display_name}</div>
                            {isOwnSeat ? (
                                <LeaveButton onClick={() => leaveTable()}>Leave</LeaveButton>
                            ) : (
                                <HoverOnlyButton onClick={() => changeSeat(index)}>Sit Here</HoverOnlyButton>
                            )}
                        </>
                    ) : (
                        <>
                            <AvatarImage $scaleFactorOnHover={0.5} src={getBotAvatarUrl("")} alt="Bot avatar" />
                            <div>{participant.bot_name}</div>
                            <HoverOnlyButton onClick={() => removeBot(index)}>Remove</HoverOnlyButton>
                            <HoverOnlyButton onClick={() => changeSeat(index)}>Sit Here</HoverOnlyButton>
                        </>
                    )
                ) : (
                    <>
                        <PrimaryButton onClick={() => addBot(index)}>Add Bot</PrimaryButton>
                        <PrimaryButton onClick={() => changeSeat(index)}>Sit Here</PrimaryButton>
                    </>
                )}
            </Seat>
        );
    };

    return (
        <SetupContainer>
            <SeatsContainer>
                {/* don't mess with the order of these players */}
                {renderSeat(table.players[2], 2)}
                {renderSeat(table.players[1], 1)}
                {renderSeat(table.players[3], 3)}
                {renderSeat(table.players[0], 0)}
            </SeatsContainer>
            <ControlsContainer>
                <SettingsContainer>
                    <SettingsGroup>
                        <SettingsItem>
                            <SettingsLabel htmlFor="gameType">Game Mode:</SettingsLabel>
                            <StyledSelect id="gameType" value={gameMode} onChange={handleGameModeChange}>
                                <option value={GameMode.STANDARD}>Standard (1000 points)</option>
                                <option value={GameMode.SHORT_600}>Short (600 points)</option>
                                <option value={GameMode.ONE_HAND}>Single Hand</option>
                                <option value={GameMode.FIXED_8}>8 Hands</option>
                                <option value={GameMode.DUPLICATE}>Duplicate</option>
                            </StyledSelect>
                        </SettingsItem>
                        <SettingsItem>
                            <CheckboxLabel>
                                <StyledCheckbox checked={shufflePlayers} onChange={handleShufflePlayersChange} />
                                Shuffle Players
                            </CheckboxLabel>
                        </SettingsItem>
                    </SettingsGroup>
                    <ButtonContainer>
                        <ActionButton onClick={startTable} $disabled={table.players.filter((p) => p !== null).length !== 4}>
                            Start Game
                        </ActionButton>
                    </ButtonContainer>
                </SettingsContainer>
            </ControlsContainer>
        </SetupContainer>
    );
};
