import React from "react";
import { NavBar } from "../components/NavBar";
import { UserContext } from "../UserContext";
import { LoadingDiv } from "../components/LoadingDiv";
import { Lobby } from "../components/Lobby";
import { Table } from "../components/Table";
import { DmGame, DmTable } from "../client/server-types-python";
import { ApiClient } from "../client/ApiClient";
import { WebSocketClient } from "../client/WebSocketClient";
import { TableContextProvider } from "../components/TableContext";

type TablesPageProps = {
    initialLoadInProgressSince: number | null;
    tableId: string | null;
    tablesState: DmTable[];
    apiClient: ApiClient;
    joinTable: (tableId: string) => void;
    createTable: () => void;
    webSocketClient: WebSocketClient | null;
    game: DmGame | null;
    // children: React.ReactNode;
};

export function TablesPage(props: TablesPageProps): JSX.Element {
    const { initialLoadInProgressSince, tableId, tablesState, apiClient, joinTable, createTable, webSocketClient } = props;
    const userContext = React.useContext(UserContext);
    if (!userContext) {
        return <></>;
    }
    const table = tableId === null ? null : tablesState.find((table) => table.id === tableId) ?? null;
    return (
        <>
            <NavBar />
            {initialLoadInProgressSince !== null ? (
                <LoadingDiv>Loading...</LoadingDiv>
            ) : table === null ? (
                <Lobby tables={tablesState} onCreateTable={createTable} onJoinTable={joinTable}></Lobby>
            ) : (
                <TableContextProvider table={table} apiClient={apiClient}>
                    <Table apiClient={apiClient} webSocketClient={webSocketClient}></Table>
                </TableContextProvider>
            )}
        </>
    );
}
