import React from "react";
import { DmGame, DmGameRequest, DmGameRequestType, DmUser } from "../client/server-types-python";
import styled from "styled-components";
import { TableContext } from "./TableContext";

const GameRequestDiv = styled.div`
    margin: 10px;
    padding: 5px 10px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.alternate_hinted_white};
`;

const ApproverSpan = styled.span`
    font-style: italic;
`;

function getDescription(request: DmGameRequest, game: DmGame) {
    switch (request.type) {
        case DmGameRequestType.UNDO:
            const numHands = game.game_hands.length;
            if (request.data["hand_index"] < numHands - 1) {
                return "rewind to a previous hand.";
            } else {
                const numMoves = (game.game_hands[numHands - 1].history ?? []).length;
                if (request.data["move_index"] < numMoves - 1) {
                    const diff = numMoves - request.data["move_index"] - 1;
                    return "undo " + diff + " step" + (diff === 1 ? "" : "s") + ".";
                }
                return "rewind. There was an error getting the details.";
            }
        case DmGameRequestType.ABORT:
            return "end the game early.";
        default:
            return "do something unknown (the text description is missing).";
    }
}

export const GameRequest: React.FC<{
    request: DmGameRequest;
}> = ({ request }) => {
    const tableContext = React.useContext(TableContext);
    const game = tableContext!.game;
    const players = tableContext!.table.players;
    const otherUsers = players.map((player) => player?.user).filter((user) => user && user.id !== request.initiator.id) as DmUser[];
    const approverIds = request.approvers.map((a) => a.id);
    const otherUserSpans = otherUsers.map((u) => (
        <ApproverSpan key={u.id}>
            {approverIds.indexOf(u.id) !== -1 ? "☑" : "☐"} {u.display_name}
        </ApproverSpan>
    ));
    return (
        <GameRequestDiv>
            <p>
                {request.initiator.display_name} would like to {getDescription(request, game)}
            </p>
            <p>{otherUserSpans}</p>
        </GameRequestDiv>
    );
};
