import { io, Socket } from "socket.io-client";
import { DmGameEvent, DmLobbyEvent, DmTableEvent } from "./server-types-python";

export class WebSocketClient {
    private socket: Socket | null = null;
    baseUrl: string;
    onLobbyEvent: (event: DmLobbyEvent) => void;
    tableSubscription: string | null = null;
    additionalTableEventSubscribers: ((event: DmTableEvent | DmGameEvent) => void)[] = [];

    /**
     *
     * @param baseUrl of the form ws://rest.of.url
     * @param onLobbyEvent callback function for when we receive a message about tables
     */
    constructor(baseUrl: string, jwt: string, onLobbyEvent: (event: DmLobbyEvent) => void, onConnect: () => void) {
        this.baseUrl = baseUrl;
        this.onLobbyEvent = onLobbyEvent;

        this.socket = io(baseUrl, {
            auth: {
                token: jwt,
            },
            transports: ["websocket", "polling"],
        });

        this.socket.on("connect", () => {
            console.log("WebSocket is connected.");
            onConnect();
        });

        this.socket.on("message", (data: any) => {
            // console.log("Received data from server:", data);
            this.onLobbyEvent(data);
        });

        this.socket.on("disconnect", (reason: string) => {
            console.log("WebSocket is disconnected. Reason:", reason);
        });
    }

    send(event: string, data: any) {
        if (this.socket && this.socket.connected) {
            this.socket.emit(event, data);
        } else {
            console.log("WebSocket is not connected. Unable to send data.");
        }
    }

    disconnect() {
        if (this.socket) {
            console.log("Explicitly disconnecting socket");
            this.socket.disconnect();
            this.socket = null;
        }
    }

    private subscribeToTopic<T>(topic: string, callback: (event: T) => void) {
        if (!this.socket) {
            console.error("Socket is not connected.");
            return;
        }
        this.socket.on(topic, (data: any) => {
            // console.log(`Received data for topic [${topic}]:`, data);
            callback(data);
        });
    }

    private unsubscribeFromTopic(topic: string) {
        if (!this.socket) {
            console.error("Socket is not connected.");
            return;
        }
        this.socket.off(topic);
    }

    subscribeToTableEvents(tableId: string, callback: (event: DmTableEvent | DmGameEvent) => void) {
        this.unsubscribeFromTableEvents();
        this.tableSubscription = `table:${tableId}`;

        const combinedCallback = (event: DmTableEvent | DmGameEvent) => {
            callback(event);
            this.additionalTableEventSubscribers.forEach((cb) => cb(event));
        };

        this.subscribeToTopic(this.tableSubscription, combinedCallback);
    }

    unsubscribeFromTableEvents() {
        if (this.tableSubscription) {
            this.unsubscribeFromTopic(this.tableSubscription);
            this.tableSubscription = null;
        }
    }

    subscribeToFeedOfSubscribedTableEvents(callback: (event: DmTableEvent | DmGameEvent) => void): () => void {
        this.additionalTableEventSubscribers.push(callback);

        return () => {
            this.additionalTableEventSubscribers = this.additionalTableEventSubscribers.filter((cb) => cb !== callback);
        };
    }
}
