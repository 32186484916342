export type PosId = 0 | 1 | 2 | 3;
export type Quartet<T> = [T, T, T, T];
export type Rank = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

export enum CardId {
    PHOENIX = 0,
    DRAGON = 1,
    DOG = 2,
    MAHJONG = 3,
    BLACK_2 = 4,
    BLACK_3 = 5,
    BLACK_4 = 6,
    BLACK_5 = 7,
    BLACK_6 = 8,
    BLACK_7 = 9,
    BLACK_8 = 10,
    BLACK_9 = 11,
    BLACK_10 = 12,
    BLACK_JACK = 13,
    BLACK_QUEEN = 14,
    BLACK_KING = 15,
    BLACK_ACE = 16,
    BLUE_2 = 17,
    BLUE_3 = 18,
    BLUE_4 = 19,
    BLUE_5 = 20,
    BLUE_6 = 21,
    BLUE_7 = 22,
    BLUE_8 = 23,
    BLUE_9 = 24,
    BLUE_10 = 25,
    BLUE_JACK = 26,
    BLUE_QUEEN = 27,
    BLUE_KING = 28,
    BLUE_ACE = 29,
    GREEN_2 = 30,
    GREEN_3 = 31,
    GREEN_4 = 32,
    GREEN_5 = 33,
    GREEN_6 = 34,
    GREEN_7 = 35,
    GREEN_8 = 36,
    GREEN_9 = 37,
    GREEN_10 = 38,
    GREEN_JACK = 39,
    GREEN_QUEEN = 40,
    GREEN_KING = 41,
    GREEN_ACE = 42,
    RED_2 = 43,
    RED_3 = 44,
    RED_4 = 45,
    RED_5 = 46,
    RED_6 = 47,
    RED_7 = 48,
    RED_8 = 49,
    RED_9 = 50,
    RED_10 = 51,
    RED_JACK = 52,
    RED_QUEEN = 53,
    RED_KING = 54,
    RED_ACE = 55,
}

export type ActionId =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30
    | 31
    | 32
    | 33
    | 34
    | 35
    | 36
    | 37
    | 38
    | 39
    | 40
    | 41
    | 42
    | 43
    | 44
    | 45
    | 46
    | 47
    | 48
    | 49
    | 50
    | 51
    | 52
    | 53
    | 54
    | 55
    | 56
    | 57
    | 58
    | 59
    | 60
    | 61
    | 62
    | 63
    | 64
    | 65
    | 66
    | 67
    | 68
    | 69
    | 70
    | 71
    | 72
    | 73
    | 74
    | 75
    | 76
    | 77
    | 78
    | 79
    | 80
    | 81
    | 82
    | 83
    | 84
    | 85
    | 86
    | 87
    | 88
    | 89
    | 90
    | 91
    | 92
    | 93
    | 94
    | 95
    | 96
    | 97
    | 98
    | 99
    | 100
    | 101
    | 102
    | 103
    | 104
    | 105
    | 106
    | 107
    | 108
    | 109
    | 110
    | 111
    | 112
    | 113
    | 114
    | 115
    | 116
    | 117
    | 118
    | 119
    | 120
    | 121
    | 122
    | 123
    | 124
    | 125
    | 126
    | 127
    | 128
    | 129
    | 130
    | 131
    | 132
    | 133
    | 134
    | 135
    | 136
    | 137
    | 138
    | 139
    | 140
    | 141
    | 142
    | 143
    | 144
    | 145
    | 146
    | 147
    | 148
    | 149
    | 150
    | 151
    | 152
    | 153
    | 154
    | 155
    | 156
    | 157
    | 158
    | 159
    | 160
    | 161
    | 162
    | 163
    | 164
    | 165
    | 166
    | 167
    | 168
    | 169
    | 170
    | 171
    | 172
    | 173
    | 174
    | 175
    | 176
    | 177
    | 178
    | 179
    | 180
    | 181
    | 182
    | 183
    | 184
    | 185
    | 186
    | 187
    | 188
    | 189
    | 190
    | 191
    | 192
    | 193
    | 194
    | 195
    | 196
    | 197
    | 198
    | 199
    | 200
    | 201
    | 202
    | 203
    | 204
    | 205
    | 206
    | 207
    | 208
    | 209
    | 210
    | 211
    | 212
    | 213
    | 214
    | 215
    | 216
    | 217
    | 218
    | 219
    | 220
    | 221
    | 222
    | 223
    | 224
    | 225
    | 226
    | 227
    | 228
    | 229
    | 230
    | 231
    | 232
    | 233
    | 234
    | 235
    | 236
    | 237
    | 238
    | 239
    | 240
    | 241
    | 242
    | 243
    | 244
    | 245
    | 246
    | 247
    | 248
    | 249
    | 250
    | 251
    | 252
    | 253
    | 254
    | 255
    | 256
    | 257
    | 258
    | 259
    | 260
    | 261
    | 262
    | 263
    | 264
    | 265
    | 266
    | 267
    | 268
    | 269
    | 270
    | 271
    | 272
    | 273
    | 274
    | 275
    | 276
    | 277
    | 278
    | 279
    | 280
    | 281
    | 282
    | 283
    | 284
    | 285
    | 286
    | 287
    | 288
    | 289
    | 290
    | 291
    | 292
    | 293
    | 294
    | 295
    | 296
    | 297
    | 298
    | 299
    | 300
    | 301
    | 302
    | 303
    | 304
    | 305
    | 306
    | 307
    | 308
    | 309
    | 310
    | 311
    | 312
    | 313
    | 314
    | 315
    | 316
    | 317
    | 318
    | 319
    | 320
    | 321
    | 322
    | 323
    | 324
    | 325
    | 326
    | 327
    | 328
    | 329
    | 330
    | 331
    | 332
    | 333
    | 334
    | 335
    | 336
    | 337
    | 338
    | 339
    | 340
    | 341;

export const ACTION_ID_PASS = 0;
export const ACTION_ID_BET = 341;
