import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HandAndScoreDisplay from "./HandAndScoreDisplay";
import { GameStatus } from "../../client/server-types-python";
import { GameDropdown } from "./GameDropdown";
import { getHandHistory } from "../../model/HandHistoryUtil";
import { InterfaceButton } from "./InterfaceButton";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../assets/arrow-right.svg";
import { TableContext } from "../TableContext";

interface ReplayBarProps {
    handleNextGame: () => void;
    leaveTable: () => void;
    requestUndo: (hand: number, move: number) => void;
    requestAbort: () => void;
}

const ReplayBarContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const Slider = styled.input<{ $replayModeEnabled: boolean; $singleValue: boolean }>`
    width: 500px;
    height: 24px;
    -webkit-appearance: none;
    appearance: none;
    background: ${(props) => (props.$replayModeEnabled ? props.theme.colors.replay_mode_light : props.theme.colors.primary_very_light)};
    outline: none;
    border-radius: 12px;
    cursor: ${(props) => (props.$singleValue ? "default" : "pointer")};

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: ${(props) => (props.$singleValue ? "0" : "40px")};
        height: ${(props) => (props.$singleValue ? "0" : "24px")};
        background: ${(props) => (props.$replayModeEnabled ? props.theme.colors.primary_dark : props.theme.colors.primary_dark)};
        cursor: ${(props) => (props.$singleValue ? "default" : "grab")};
        border-radius: 12px;
    }

    &::-moz-range-thumb {
        width: ${(props) => (props.$singleValue ? "0" : "40px")};
        height: ${(props) => (props.$singleValue ? "0" : "24px")};
        background: ${(props) => (props.$replayModeEnabled ? props.theme.colors.primary_dark : props.theme.colors.primary_dark)};
        cursor: ${(props) => (props.$singleValue ? "default" : "grab")};
        border-radius: 12px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ArrowButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

const ArrowIcon = styled.svg`
    width: 36px;
    height: 36px;
    fill: ${(props) => props.theme.colors.primary_dark};
`;

const UndoButton = styled(InterfaceButton)`
    margin-right: 6px;
    width: 110px;
`;

export const ReplayBar: React.FC<ReplayBarProps> = ({ handleNextGame, leaveTable, requestUndo, requestAbort }) => {
    const tc = React.useContext(TableContext)!;
    const [sliderValue, setSliderValue] = useState(tc.replayState.replayMoveCursor);

    const maxVal = tc.gameplayContext!.maxDisplayedHistoryIndex;

    // If the maxVal is 0, then the slider would only have one value. In this case we'll hide the slider thumb.
    const isSingleValue = maxVal === 0;

    useEffect(() => {
        if (!tc.replayState.replayModeEnabled) {
            setSliderValue(maxVal);
        } else {
            setSliderValue(tc.replayState.replayMoveCursor);
        }
    }, [tc.replayState.replayModeEnabled, tc.replayState.replayMoveCursor, maxVal]);

    const handleStepLeft = () => {
        const newValue = Math.max(0, sliderValue - 1);
        setSliderValue(newValue);
        updateReplayState(newValue);
    };

    const handleStepRight = () => {
        const newValue = Math.min(maxVal, sliderValue + 1);
        setSliderValue(newValue);
        updateReplayState(newValue);
    };

    const updateReplayState = (newMoveCursor: number) => {
        const inReplay =
            newMoveCursor !== tc.nowplayState.nowplayMoveCursor || tc.replayState.replayHandCursor !== tc.nowplayState.nowplayHandCursor;
        // If we are not already replaying a hand, the relevant hand is the current one.
        const newHandCursor = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
        tc.setReplayState((prevState) => ({
            ...prevState,
            replayModeEnabled: inReplay,
            replayMoveCursor: newMoveCursor,
            replayHandCursor: newHandCursor,
        }));
    };

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMoveCursor = parseInt(event.target.value, 10);
        setSliderValue(newMoveCursor);
        updateReplayState(newMoveCursor);
    };

    const handCursor = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const handNumber = handCursor + 1;

    const game = tc.game;

    const handIndex = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const fullHandHistory = getHandHistory(game, handIndex, tc.handHistoryCache) ?? [];
    const handHistory = fullHandHistory.slice(
        0,
        (tc.replayState.replayModeEnabled ? tc.replayState.replayMoveCursor : tc.nowplayState.nowplayMoveCursor) + 1
    );

    const gameHandIndex = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const moveIndex = handHistory.length - 1;

    const requestUndoFunc = tc.replayState.replayModeEnabled
        ? () => requestUndo(tc.replayState.replayHandCursor, tc.replayState.replayMoveCursor)
        : moveIndex > 0
        ? () => requestUndo(gameHandIndex, moveIndex - 1)
        : gameHandIndex > 0
        ? () => requestUndo(gameHandIndex - 1, game.game_hands[gameHandIndex - 1].history!.length - 1)
        : undefined;

    let nsScore = 0,
        ewScore = 0;
    for (let i = 0; i <= tc.gameplayContext!.displayedGameHandIndex; i++) {
        if (i !== tc.gameplayContext!.displayedGameHandIndex || tc.gameplayContext!.displayedPlayState.is_hand_over) {
            nsScore += game.game_hands[i].play_state.ns_score;
            ewScore += game.game_hands[i].play_state.ew_score;
        }
    }

    return (
        <ReplayBarContainer>
            <HandAndScoreDisplay
                nsScore={nsScore}
                ewScore={ewScore}
                handNumber={handNumber}
                scoreModalProps={{
                    table: tc.table,
                    replayState: tc.replayState,
                    setReplayState: tc.setReplayState,
                    handHistoryCache: tc.handHistoryCache,
                    playerIsNs: tc.playerSeat % 2 === 0,
                    gameIsOver: tc.game.status === GameStatus.COMPLETE, // TODO is this correct?
                    nextGameFunc: handleNextGame,
                }}
            />
            <ButtonContainer>
                <ArrowButton onClick={handleStepLeft} disabled={sliderValue === 0}>
                    <ArrowIcon as={LeftArrow} />
                </ArrowButton>
                <Slider
                    $replayModeEnabled={tc.replayState.replayModeEnabled}
                    $singleValue={isSingleValue}
                    type="range"
                    min={0}
                    max={maxVal}
                    value={sliderValue}
                    onChange={handleSliderChange}
                />
                <ArrowButton onClick={handleStepRight} disabled={sliderValue === maxVal}>
                    <ArrowIcon as={RightArrow} />
                </ArrowButton>
            </ButtonContainer>
            <ButtonContainer>
                <UndoButton disabled={!requestUndoFunc} onClick={requestUndoFunc}>
                    {tc.replayState.replayModeEnabled ? "Undo to Here" : "Undo 1 Step"}
                </UndoButton>
                <GameDropdown leaveTable={leaveTable} requestAbort={requestAbort} gameStatus={game.status} />
            </ButtonContainer>
        </ReplayBarContainer>
    );
};
